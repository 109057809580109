<template>
    <div class="heroe">
        <div class="heroe__languages">
            <languages variant="link" toggle-class="heroe__language-selector" />
        </div>
        <div v-if="loadingSites" class="h-100 d-flex align-items-center justify-content-center">
            <auth-hero-skeleton />
        </div>
        <template v-else>
            <component :is="currentHero" class="heroe__content" />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AuthHeroSkeleton from '@/components/ui/skeletons/AuthHeroSkeleton.vue';
import Languages from "@/components/Languages";

// Heroes
import Accounts from '@/components/auth/heroes/Accounts.vue';
import EcartPay from '@/components/auth/heroes/EcartPay.vue';
import EcartSeller from '@/components/auth/heroes/EcartSeller.vue';
import Envia from '@/components/auth/heroes/Envia.vue';
import Fulfillment from '@/components/auth/heroes/Fulfillment.vue';
import FulfillmentMibox from '@/components/auth/heroes/FulfillmentMibox.vue';
import Returns from '@/components/auth/heroes/Returns.vue';
import Wms from '@/components/auth/heroes/Wms.vue';

export default {
    name: 'Heroe',
    components: {
        AuthHeroSkeleton,
        Languages,
        Accounts,
        EcartPay,
        EcartSeller,
        Envia,
        Fulfillment,
        FulfillmentMibox,
        Returns,
        Wms,
    },
    computed: {
        ...mapGetters('ui', ['sites', 'loadingSites']),
        currentHero() {
            const site_id = this.$route.query.site_id;
            const site = this.sites.find(site => site.site_id === site_id);

            if (site_id === '66317a0b6c6ae21e0bfb5c9b') {
                return  'FulfillmentMibox';
            }

            if (site_id && site) {
                const heroes_map = {
                    'Ecart Pay': 'EcartPay',
                    'Ecart Seller': 'EcartSeller',
                    'Envia': 'Envia',
                    'Fulfillment': 'Fulfillment',
                    'Returns': 'Returns',
                    'WMS': 'Wms',
                };

                if (heroes_map[site.name]) {
                    return heroes_map[site.name];
                }
            }

            return 'Accounts';
        },
    },
};
</script>

<style lang="scss">
.heroe {
    background-color: #EDF2F9;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: calc(100vw / 2);

    &__languages {
        position: absolute;
        right: 65px;
        top: 30px;
        text-align: right;
        margin-bottom: 15px;
        width: 100%;
    }

    &__language-selector {
        font-weight: 700 !important;
        text-decoration: none !important;
    }

    &__content {
        height: 100%;
        padding: 90px 65px 30px 65px;
        transition: background-color 0.15s ease-in-out;
        width: 100%;
    }
}
</style>
