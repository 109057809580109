<template>
    <div class="ecart-seller-hero">
        <div class="ecart-seller-hero__image-container">
            <img class="ecart-seller-hero__image" alt="Tendencys"
                src="@/assets/images/auth/hero-ecart-seller.png" />
        </div>
        <div class="ecart-seller-hero__text-container">
            <div class="text-left">
                <img class="ecart-seller-hero__logo" alt="Tendencys"
                    src="@/assets/images/logos/ecart-seller-color.svg" />
                <h2 class="ecart-seller-hero__title"
                    v-sanitize="$t('<b>Millions of shoppers</b> can not wait to see what you have in store')">.</h2>
                <p class="ecart-seller-hero__text">{{ $t('Join a marketplace where nearly 50 million buyers around the world shop for a variety of items') }}.
                </p>
                <router-link class="ecart-seller-hero__hero-link" to="/#apps">
                    {{ $t('See applications') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EcartSellerHero',
};
</script>

<style lang="scss">
.ecart-seller-hero {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__text-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__logo {
        height: auto;
        margin-bottom: 20px;
        max-width: 250px;
        width: 100%;
    }

    &__title {
        color: #2F2F33;
        font-size: 24px;
        font-weight: 600;
        max-width: 400px;

        b {
            color: #68AE34;
            font-weight: 700;
        }
    }

    &__text {
        color: #2F2F33;
        font-size: 16px;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 10px;
        max-width: 300px;
    }

    &__image-container {
        margin-top: -80px;
        margin-left: auto;
        margin-right: -65px;
        height: 100%;
        max-height: calc(100vh - 30px - 280px);
    }

    &__image {
        width: auto;
        height: 100%;
    }
}
</style>
