<template>
    <div class="auth-layout">
        <div class="auth-layout__column">
            <div class="auth-layout__content">
                <div class="auth-layout__mobile-languages">
                    <languages variant="link" toggle-class="auth-layout__mobile-language-selector" />
                </div>
                <div class="auth-layout__page">
                    <div class="w-100">
                        <router-view />
                    </div>
                </div>
                <site-list v-if="showSiteList" />
                <p class="auth-layout__copy">&copy; {{ new Date().getFullYear() }} Accounts. {{ $t('All rights reserved') }}</p>
            </div>
        </div>
        <div class="auth-layout__column auth-layout__hero-column d-none d-lg-block">
            <hero />
        </div>
    </div>
</template>

<script>
import Hero from '@/components/auth/Hero.vue';
import Languages from "@/components/Languages";
import SiteList from '@/components/auth/SiteList.vue';

export default {
    name: 'auth-layout',
    components: {
        Hero,
        Languages,
        SiteList,
    },
    computed: {
        showSiteList() {
            const { site_id } = this.$route.query;

            return this.$route.name === 'accountsLogin' && site_id !== '66317a0b6c6ae21e0bfb5c9b';
        },
    },
};
</script>

<style lang="scss">
.auth-layout {
    display: flex;

    &__column {
        width: 50%;

        @media (max-width: 991px) {
            width: 100%;
        }
    }

    &__content {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        padding: 30px 15px;
        position: relative;
        max-width: calc(1140px / 2);
        margin-left: auto;
        margin-right: auto;

        @media(max-width: 992px) {
            background-color: transparent;
        }

        @media(min-width: 992px) {
            padding: 30px 50px;
        }

        @media(max-width: 768px) {
            min-height: 100vh;
        }
    }

    &__mobile-languages {
        display: block;
        margin-left: auto;
        margin-bottom: 10px;
        
        @media(min-width: 992px) {
            display: none;
        }
    }

    &__page {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__hero-column {
        position: relative;
    }

    &__mobile-accounts-logo {
        margin-bottom: 15px;
        max-width: 155px;
        width: 100%;
    }

    &__mobile-language-selector {
        font-weight: 700 !important;
        color: #001CBC !important;
    }

    &__hero-link {
        font-weight: 700;
        margin-bottom: 15px;
        color: #2F2F33;

        &:hover {
            color: #2F2F33;
            text-decoration: none;
        }
    }

    &__copy {
        color: #AAAAAA;
        margin-bottom: 0;

    }
}
</style>
