<template>
    <div class="site-list">
        <p class="site-list__text">
            {{ $t('Login with the same user to all our platforms:') }}
        </p>
        <div class="site-list__icons-container">
            <div class="site-list__list-item" v-for="site in sitesWithImages" :key="site.site_id">
                <a
                    class="site-list__link"
                    :class="{ 'site-list__link--disabled': !getSiteUrl(site) }"
                    v-sanitize-href="getSiteUrl(site)"
                    rel="noopener"
                    target="_blank"
                >
                    <img class="site-list__list-icon" :src="site.imageUrl" :alt="site.name" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AppList',
    data() {
        return {
            siteUrls: {
                'Ecart Pay': 'https://ecartpay.com/',
                'Ecart Seller': 'https://ecart.com/',
                'Returns': 'https://returns.envia.com/',
                'Envia': 'https://envia.com/',
                'Fulfillment': 'https://fulfillment.envia.com/',
                'Partners': 'https://partners.envia.com/',
                'WMS': 'https://wms.envia.com/',
            },
        };
    },
    computed: {
        ...mapGetters('ui', ['sites']),
        sitesWithImages() {
            const sitesWithImages = [];
            this.sites.forEach((site) => {
                try {
                    const imageUrl = require(`@/assets/images/icons/sites/${site.name.toLowerCase().replace(/ /g, '-')}.svg`);
                    sitesWithImages.push({
                        ...site,
                        imageUrl,
                    });
                } catch (error) {
                    console.error(`Error fetching image for ${site.name}: ${error.message}`);
                }
            });
            return sitesWithImages;
        },
    },
    methods: {
        getSiteUrl(site) {
            return this.siteUrls?.[site.name] || '';
        }
    }
};
</script>

<style lang="scss">
.site-list {
    margin: 30px 0;

    &__text {
        font-size: 0.9rem;
        font-weight: 500;
        color: $accounts-secondary-600;
        margin-bottom: 10px;
    }

    &__icons-container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }

    &__list-item {
        flex-shrink: 0;
    }

    &__link {
        &--disabled {
            pointer-events: none;
        }
    }

    &__list-icon {
        height: 60px;
        width: 60px;
    }
}
</style>
