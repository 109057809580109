<template>
    <div class="fulfillment-hero">
        <img class="fulfillment-hero__banner" alt="Tendencys" :src="getHeroImage()" @error="getHeroImageError" />
    </div>
</template>

<script>
export default {
    name: 'FulfillmentMiboxHero',
    methods: {
        getHeroImage() {
            let { lang } = this.$route.query;
            return `${process.env.VUE_APP_S3_URL}/images/login/fulfillment/mibox-${lang || 'en-US'}.webp`;       
        },
        getHeroImageError(event) {
            event.target.src = `${process.env.VUE_APP_S3_URL}/images/login/fulfillment/mibox-en-US.webp`;
        },
    },
};
</script>

<style lang="scss">
.fulfillment-hero {
    align-items: center;
    background: #ecf0ff;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    &__banner {
        width: 100%;
    }
}
</style>
