<template>
    <img class="hero-image" :alt="alt" :src="src" @error="onError" />
</template>

<script>
export default {
    name: 'HeroImage',
    props: {
        alt: {
            type: String,
            required: false,
            default: null,
        },
        fallbackSrc: {
            type: String,
            required: false,
            default: null,
        },
        src: {
            type: String,
            required: true,
            default: null,
        },
    },
    methods: {
        async onError(event) {
            if (!this.fallbackSrc) {
                return;
            }

            try {
                const image = await new Promise((resolve, reject) => {
                    const img = new Image();

                    img.src = this.fallbackSrc;

                    img.onload = () => {
                        resolve(this.fallbackSrc);
                    };

                    img.onerror = () => {
                        reject(`Image not found: ${this.fallbackSrc}`);
                    };
                });

                event.target.src = image;
            } catch (error) {
                console.error(error);
            }
        }
    },
};
</script>
