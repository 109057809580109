<template>
    <div class="returns-hero">
        <div class="returns-hero__text-container">
            <div class="text-center">
                <img class="returns-hero__logo" alt="Tendencys"
                    src="@/assets/images/logos/returns-color.svg" />
                <h2 class="returns-hero__title"
                    v-sanitize="$t('The <b>easiest returns system</b> to use')">.</h2>
                <p class="returns-hero__text">{{ $t('Build loyalty and provide optimal follow-up to your customers') }}</p>
                <router-link class="auth-layout__hero-link" to="/#apps">
                    {{ $t('See applications') }}
                </router-link>
            </div>
        </div>
        <div class="returns-hero__image-container">
            <img class="returns-hero__image" alt="Tendencys"
                src="@/assets/images/auth/hero-returns.svg" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReturnsHero',
};
</script>

<style lang="scss">
.returns-hero {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__text-container {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        height: auto;
        margin-bottom: 20px;
        max-width: 280px;
        width: 100%;
    }

    &__title {
        color: #2F2F33;
        font-size: 24px;
        font-weight: 600;
        margin-left: auto;
        margin-right: auto;
        max-width: 430px;

        b {
            color: #00B2C0;
            font-weight: 700;
        }
    }

    &__text {
        color: #2F2F33;
        font-size: 16px;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        max-width: 320px;
    }

    &__image-container {
        margin-bottom: -30px;
        margin-left: -65px;
        margin-right: -65px;
    }

    &__image {
        width: 100%;
        height: auto;
    }    
}
</style>
