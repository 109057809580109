<template>
    <div class="fulfillment-hero">
        <hero-image class="fulfillment-hero__banner" alt="Fulfillment" :src="imageSrc" :fallback-src="imageFallbackSrc" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import HeroImage from '@/components/auth/HeroImage.vue';

export default {
    name: 'FulfillmentHero',
    components: { HeroImage },
    computed: {
        ...mapGetters('ui', ['language']),
        imageSrc() {
            return `${process.env.VUE_APP_S3_URL}/images/login/fulfillment/${this.language || 'en-US'}.webp`;
        },
        imageFallbackSrc() {
            return `${process.env.VUE_APP_S3_URL}/images/login/fulfillment/en-US.webp`;
        },
    },
};
</script>

<style lang="scss">
.fulfillment-hero {
    align-items: center;
    background: #ecf0ff;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    &__banner {
        width: 100%;
    }
}
</style>
