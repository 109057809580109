<template>
    <div class="auth-hero-skeleton">
        <template v-if="!isMobile">
            <div class="w-100 mb-3 d-flex justify-content-center">
                <b-skeleton-img width="300px"></b-skeleton-img>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="75%"></b-skeleton>
                <b-skeleton width="20%"></b-skeleton>
            </div>
        </template>
        <template v-else>
            <div class="w-100 d-flex justify-content-center mb-3">
                <b-skeleton-img width="150px" height="50px" no-aspect></b-skeleton-img>
            </div>
            <div class="w-100 d-flex justify-content-center mb-3">
                <b-skeleton-img width="243px" height="85px" no-aspect></b-skeleton-img>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <b-skeleton width="243px"></b-skeleton>
                <b-skeleton width="243px"></b-skeleton>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AuthHeroSkeleton',
    props: {
        isMobile: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
};
</script>

<style lang="scss">
    .auth-hero-skeleton {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        text-align: center;
        width: 525px;
    }
</style>
