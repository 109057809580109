<template>
    <div class="ecartpay-hero">
        <div class="ecartpay-hero__text-container">
            <div class="text-left">
                <img class="ecartpay-hero__logo" alt="Tendencys"
                    src="@/assets/images/logos/ecart-pay-color.svg" />
                <h2 class="ecartpay-hero__title"
                    v-sanitize="$t('We develop the <b>safest online payment processor</b> for you')">.</h2>
                <p class="ecartpay-hero__text">{{ $t('Receive payments, send refunds and create billing invoices') }}</p>
                <router-link class="ecartpay-hero__hero-link" to="/#apps">
                    {{ $t('See applications') }}
                </router-link>
            </div>
        </div>
        <div class="ecartpay-hero__image-container">
            <img class="ecartpay-hero__image" alt="Tendencys"
                src="@/assets/images/auth/hero-ecart-pay.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'EcartPayHero',
};
</script>

<style lang="scss">
.ecartpay-hero {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__text-container {
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        align-items: center;
    }

    &__logo {
        height: auto;
        margin-bottom: 20px;
        max-width: 370px;
        width: 100%;
    }

    &__title {
        color: #2F2F33;
        font-size: 24px;
        font-weight: 600;
        max-width: 400px;

        b {
            color: #68AE34;
            font-weight: 700;
        }
    }

    &__text {
        color: #2F2F33;
        font-size: 16px;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 10px;
        max-width: 300px;
    }

    &__image-container {
        margin-bottom: -30px;
        margin-left: auto;
        margin-right: -65px;
        width: 100%;
    }

    &__image {
        width: 100%;
        height: auto;
    }
}
</style>
