<template>
    <div class="envia-hero">
        <img class="envia-hero__banner" alt="Tendencys" :src="getHeroImage()" @error="getHeroImageError" />
    </div>
</template>

<script>
export default {
    name: 'EnviaHero',
    methods: {
        getHeroImage() {
            let { lang } = this.$route.query;
            return `${process.env.VUE_APP_S3_URL}/images/login/envia/${lang || 'en-US'}.png`;       
        },
        getHeroImageError(event) {
            event.target.src = `${process.env.VUE_APP_S3_URL}/images/login/envia/en-US.png`;
        },
    },
};
</script>

<style lang="scss">
.envia-hero {
    align-items: center;
    background: #dae9eb;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    &__banner {
        width: 100%;
    }
}
</style>
