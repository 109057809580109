<template>
    <div class="accounts-hero">
        <div class="accounts-hero__text-container">
            <div class="text-left">
                <h2 class="accounts-hero__title"
                    v-sanitize="$t('A secure, smart and easy to use account')"></h2>
                <p class="accounts-hero__text">{{ $t('Improve your productivity and more easily manage your applications that are integrated into your same account.') }}</p>
                <router-link class="accounts-hero__hero-link" to="/#apps">
                    {{ $t('See applications') }}
                </router-link>
            </div>
        </div>
        <div class="accounts-hero__image-container">
            <img class="accounts-hero__image img-fluid" alt="Tendencys"
                src="@/assets/images/auth/hero-login.svg" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountsHero',
};
</script>

<style lang="scss">
.accounts-hero {
    &__text-container {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
    }

    &__logo {
        height: auto;
        margin-bottom: 20px;
        max-width: 370px;
        width: 100%;
    }

    &__title {
        color: #2F2F33;
        font-size: 24px;
        font-weight: 600;
        max-width: 400px;

        b {
            color: #00BDC7;
            font-weight: 700;
        }
    }

    &__text {
        color: #2F2F33;
        font-size: 16px;
        font-weight: 400;
        line-height: 110%;
        margin-bottom: 10px;
        max-width: 300px;
    }

    &__image-container {
        margin-left: auto;
        margin-right: -65px;
        width: 100%;
    }

    &__image {
        width: 100%;
    }
}
</style>