<template>
    <div class="wms-hero">
        <img class="wms-hero__banner" alt="Tendencys" src="@/assets/images/auth/hero-wms.jpg"/>
    </div>
</template>

<script>
export default {
    name: 'WmsHero',
};
</script>

<style lang="scss">
.wms-hero {
    align-items: center;
    background: #ecf0ff;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    &__banner {
        width: 100%;
    }
}
</style>
